<template>
  <div class="relative">
    <b-button
      type="is-grey"
      inverted
      class="mr-4"
      tag="router-link"
      :to="{ name: 'My Cart' }"
    >
      <b-icon icon="cart" type="" />
    </b-button>
    <b-icon
      v-if="hasProducts"
      icon="circle-medium"
      type="is-gold"
      class="dot"
      :class="{
        'animate__animated animate__heartBeat animate__faster': pulse
      }"
    />
  </div>
</template>

<script>
import { Events } from "@/events.js";
export default {
  data: () => ({
    pulse: false
  }),
  methods: {
    pulseIcon() {
      this.pulse = true;
      setTimeout(() => {
        this.pulse = false;
      }, 1000);
    }
  },
  computed: {
    hasProducts() {
      if (
        this.$store.getters.userHasPermission([
          "write-carts",
          "write-custom-carts"
        ])
      )
        return false;
      if (this.$store.getters.clientCart) {
        return this.$store.getters.clientCart.products.length;
      } else {
        return this.$store.getters.userCart
          ? this.$store.getters.userCart.products.length
          : false;
      }
    }
  },
  beforeMount() {
    Events.$on("cart-updated", this.pulseIcon);
  }
};
</script>

<style lang="scss" scoped>
.dot {
  position: absolute;
  top: -10px;
  right: 5px;
  z-index: 1;
}
</style>
