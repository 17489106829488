var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"header",staticClass:"shadow-xs",attrs:{"id":"header"}},[_c('div',{staticClass:"container px-4"},[_c('div',{staticClass:"px-2"},[_c('div',{staticClass:"-mx-4 h-24 flex flex-wrap items-center"},[_c('div',{staticClass:"w-1/4 px-2"},[_c('div',{staticClass:"flex justify-start"},[_c('router-link',{attrs:{"to":{ name: 'Home' }}},[_c('img',{staticClass:"h-16 m-0",attrs:{"alt":"mkthemedattractions-logo","src":require("@/assets/logo.svg")}})])],1)]),_c('div',{staticClass:"w-1/2 px-2"},[_c('Search')],1),_c('div',{staticClass:"w-1/4 px-2 flex justify-end"},[_c('MenuToggle'),(
              (_vm.userHasPermission('write-custom-carts') &&
                _vm.$store.getters.userRole != 'super-admin') ||
                (_vm.userHasPermission('write-carts') &&
                  _vm.$store.getters.userRole != 'super-admin')
            )?_c('CartIndicator'):_vm._e(),_c('b-dropdown',{attrs:{"aria-role":"list","close-on-click":false,"position":"is-bottom-left"},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
            var active = ref.active;
return _c('b-button',{attrs:{"type":"is-grey","inverted":""}},[_c('b-icon',{attrs:{"icon":"account-circle-outline"}}),_c('b-icon',{attrs:{"icon":active ? 'menu-up' : 'menu-down'}})],1)}}])},[_c('b-dropdown-item',{attrs:{"aria-role":"listitem"}},[_c('small',[_vm._v("Logged in as")]),_c('br'),_vm._v(" "+_vm._s(_vm.$store.getters.activeUser.is_client ? ((_vm.$store.getters.clientCart ? _vm.$store.getters.clientCart.client_name : "") + " via " + (_vm.$store.getters.activeUser.name)) : _vm.$store.getters.activeUser ? _vm.$store.getters.activeUser.name : "")+" ")]),(
                _vm.userHasPermission('write-account') &&
                  _vm.$store.getters.userRole != 'super-admin'
              )?_c('b-dropdown-item',{attrs:{"aria-role":"listitem","has-link":""}},[_c('router-link',{staticClass:"has-text-black",attrs:{"to":{ name: 'My Account' }}},[_vm._v(" Account ")])],1):_vm._e(),(
                _vm.userHasPermission('write-carts') &&
                  _vm.$store.getters.userRole != 'super-admin'
              )?_c('b-dropdown-item',{attrs:{"aria-role":"listitem","has-link":""}},[_c('router-link',{staticClass:"flex has-text-black",attrs:{"to":{ name: 'My Orders' }}},[_vm._v(" My Orders ")])],1):_vm._e(),(_vm.userHasPermission('read-dashboard'))?_c('b-dropdown-item',{attrs:{"aria-role":"list-item"}},[_c('router-link',{staticClass:"has-text-black",attrs:{"to":{ name: 'Dashboard' }}},[_vm._v("Dashboard")])],1):_vm._e(),_c('b-dropdown-item',{attrs:{"aria-role":"listitem","has-link":""}},[_c('a',{staticClass:"has-text-black",on:{"click":_vm.logout}},[_vm._v(" Logout ")])])],1)],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }