<template>
  <div class="relative" v-click-outside="hideResults">
    <div
      class="control has-icons-left has-icons-right is-medium"
      :class="{ 'is-loading': isFetching }"
    >
      <input
        type="text"
        class="input is-medium search"
        placeholder="Search"
        v-model="query"
        @click="(showResults = true), (isFocused = true)"
        @keyup.enter="goToProductsPage()"
      />
      <span class="icon is-left">
        <b-icon icon="magnify"></b-icon>
      </span>
      <span
        class="icon is-right"
        v-if="query && !isFetching"
        @click="(products = []), (query = '')"
      >
        <b-icon icon="close" type="is-grey"></b-icon>
      </span>
    </div>
    <!-- <transition enter-active-class="animate__animated animate__fadeIn animate__faster"  leave-active-class="animate__animated animate__fadeOut animate__faster"> -->
    <div
      class="search-results shadow flex flex-wrap"
      v-if="products.length && showResults"
    >
      <div class="w-1/2 p-4">
        <div class="heading">Results</div>
        <div class="products" ref="products" @scroll="onScroll">
          <b-loading :is-full-page="false" v-model="isFetching" />
          <router-link
            @click.native="showResults = false"
            class="flex items-center py-2 has-text-grey"
            v-for="product in products"
            :key="product.id"
            :to="{
              name: 'Product',
              params: { id: product.base ? product.base.slug : product.slug },
              query: { v: product.base ? product.id : undefined }
            }"
          >
            <div class="px-2">
              <img
                width="50"
                :src="
                  product.image ? product.image.thumb : $default_image_thumb
                "
                loading="lazy"
              />
            </div>
            <div class="px-2">
              {{ product.name }}
              <br />
              <small>{{ product.code }}</small>
            </div>
          </router-link>
        </div>
      </div>
      <div class="w-1/2 p-4">
        <div class="heading">suggestions</div>
        <div class="suggestions">
          <router-link
            class="inline-flex mx-1 mb-1"
            v-for="item in suggestions"
            :key="item.id"
            :to="{
              name: 'Category',
              params: { id: item.parent ? item.parent.slug : item.slug },
              query: { show: item.parent ? item.slug : undefined }
            }"
            @click.native="hideResults"
          >
            <b-tag>{{ item.name }}</b-tag>
          </router-link>
        </div>
      </div>
    </div>
    <!-- </transition> -->
  </div>
</template>

<script>
import store from "@/store";
export default {
  data: () => ({
    showResults: false,
    isFocused: false,
    isFetching: false,
    query: "",
    savedQuery: "",
    products: [],
    page: 1,
    totalPages: 1
  }),
  watch: {
    query() {
      this.getProducts(this.query);
    },
    $route(to) {
      // if(to.query.search) {
      this.query = to.query.search;
      this.showResults = false;
      // }
    }
  },
  methods: {
    getProducts: _.debounce(function(query) {
      // String update
      if (this.savedQuery !== query) {
        this.savedQuery = query;
        this.products = [];
        this.page = 1;
        this.totalPages = 1;
      }
      // String cleared
      if (!query) {
        this.products = [];
        this.page = 1;
        this.totalPages = 1;
        return;
      }
      // Reached last page
      if (this.page > this.totalPages) {
        return;
      }
      this.isFetching = true;
      axios({
        url: "client/products",
        method: "get",
        params: {
          page: this.page,
          search: this.savedQuery
        },
        headers: store.getters.authorizationHeader
      })
        .then(({ data }) => {
          if (data.data.length && this.isFocused) this.showResults = true;
          data.data.forEach(item => this.products.push(item));
          this.page++;
          this.totalPages = data.last_page;
        })
        .catch(error => {
          throw error;
        })
        .finally(() => {
          this.isFetching = false;
        });
    }, 500),
    getMoreProducts: _.debounce(function() {
      this.getProducts(this.savedQuery);
    }, 250),
    onScroll() {
      let currentScroll =
        this.$refs["products"].scrollHeight - this.$refs["products"].scrollTop;
      let endScroll = this.$refs["products"].offsetHeight;

      if (_.floor(currentScroll) === endScroll) {
        this.getMoreProducts();
      }
    },
    hideResults() {
      this.showResults = false;
    },
    goToProductsPage() {
      if (!this.query) return;
      if (
        this.$route.query.search &&
        this.$route.query.search.toLowerCase() == this.query.toLowerCase()
      )
        return;
      this.$router.push({
        name: "ProductList",
        query: { search: this.query }
      });
    }
  },
  computed: {
    suggestions() {
      return _.uniqBy(_.flattenDeep(_.map(this.products, "categories")), "id");
    }
  },
  mounted() {
    this.query = this.$route.query.search;
    this.showResults = false;
  }
};
</script>

<style lang="scss" scoped>
$baseColor: #ad9861;
.search {
  border: 1px solid hsl(0, 0, 93%);
  border: transparent;
  border-radius: 3px;
  width: 100%;
  background: hsl(0, 0, 96%);
}

.search-results {
  background: white;
  border-radius: 3px;
  position: absolute;
  top: calc(100% + 3px);
  width: 100%;
  z-index: 2;
}
.products,
.suggestions {
  //   position: relative;
  height: 300px;
  overflow-y: scroll;
}

.is-right {
  pointer-events: auto !important;
  cursor: pointer;
}
</style>
