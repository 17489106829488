<template>
  <b-button type="is-grey" inverted class="mr-4">
    <b-icon icon="menu" @click.native="showMenu" />
  </b-button>
</template>

<script>
export default {
  methods: {
    showMenu() {
      this.$store.commit("toggleShowMenu", true);
    }
  }
};
</script>
