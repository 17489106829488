var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.hideResults),expression:"hideResults"}],staticClass:"relative"},[_c('div',{staticClass:"control has-icons-left has-icons-right is-medium",class:{ 'is-loading': _vm.isFetching }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.query),expression:"query"}],staticClass:"input is-medium search",attrs:{"type":"text","placeholder":"Search"},domProps:{"value":(_vm.query)},on:{"click":function($event){(_vm.showResults = true), (_vm.isFocused = true)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.goToProductsPage()},"input":function($event){if($event.target.composing){ return; }_vm.query=$event.target.value}}}),_c('span',{staticClass:"icon is-left"},[_c('b-icon',{attrs:{"icon":"magnify"}})],1),(_vm.query && !_vm.isFetching)?_c('span',{staticClass:"icon is-right",on:{"click":function($event){(_vm.products = []), (_vm.query = '')}}},[_c('b-icon',{attrs:{"icon":"close","type":"is-grey"}})],1):_vm._e()]),(_vm.products.length && _vm.showResults)?_c('div',{staticClass:"search-results shadow flex flex-wrap"},[_c('div',{staticClass:"w-1/2 p-4"},[_c('div',{staticClass:"heading"},[_vm._v("Results")]),_c('div',{ref:"products",staticClass:"products",on:{"scroll":_vm.onScroll}},[_c('b-loading',{attrs:{"is-full-page":false},model:{value:(_vm.isFetching),callback:function ($$v) {_vm.isFetching=$$v},expression:"isFetching"}}),_vm._l((_vm.products),function(product){return _c('router-link',{key:product.id,staticClass:"flex items-center py-2 has-text-grey",attrs:{"to":{
            name: 'Product',
            params: { id: product.base ? product.base.slug : product.slug },
            query: { v: product.base ? product.id : undefined }
          }},nativeOn:{"click":function($event){_vm.showResults = false}}},[_c('div',{staticClass:"px-2"},[_c('img',{attrs:{"width":"50","src":product.image ? product.image.thumb : _vm.$default_image_thumb,"loading":"lazy"}})]),_c('div',{staticClass:"px-2"},[_vm._v(" "+_vm._s(product.name)+" "),_c('br'),_c('small',[_vm._v(_vm._s(product.code))])])])})],2)]),_c('div',{staticClass:"w-1/2 p-4"},[_c('div',{staticClass:"heading"},[_vm._v("suggestions")]),_c('div',{staticClass:"suggestions"},_vm._l((_vm.suggestions),function(item){return _c('router-link',{key:item.id,staticClass:"inline-flex mx-1 mb-1",attrs:{"to":{
            name: 'Category',
            params: { id: item.parent ? item.parent.slug : item.slug },
            query: { show: item.parent ? item.slug : undefined }
          }},nativeOn:{"click":function($event){return _vm.hideResults($event)}}},[_c('b-tag',[_vm._v(_vm._s(item.name))])],1)}),1)])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }