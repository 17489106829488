<template>
  <div class="block shadow-xs border border-transparent">
    <div class="gallery">
      <router-link :to="url">
        <b-image
          :src="product.image ? product.image.thumb : $default_image_thumb"
          :placeholder="$blank_image_thumb"
          :alt="product.slug"
          ratio="1by1"
          lazy
        ></b-image>
        <!-- <b-image
          :src="images[index] ? images[index].thumb : $default_image_thumb"
          :alt="product.slug"
          ratio="1by1"
          lazy
        ></b-image> -->
      </router-link>
      <!-- <div class="new" v-if="isNew">new</div> -->
      <!-- <b-icon
        icon="chevron-left"
        @click.native="decrementIndex"
        v-if="images.length > 1"
        class="gallery-control gallery-control-left shadow-xs"
      ></b-icon>
      <b-icon
        icon="chevron-right"
        @click.native="incrementIndex"
        v-if="images.length > 1"
        class="gallery-control gallery-control-right shadow-xs"
      ></b-icon> -->
    </div>
    <div class="px-4 my-2">
      <div class="-mx-2 flex flex-wrap">
        <router-link
          @mouseover.native="setData(item)"
          class="w-1/4 px-1 mb-2"
          :to="{ name: 'Product', params: { id: item.slug } }"
        >
          <b-image
            class=""
            :src="item.image ? item.image.thumb : $default_image_thumb"
            :alt="item.slug"
            ratio="1by1"
            lazy
          ></b-image>
        </router-link>
        <router-link
          @mouseover.native="setData(variant, item)"
          class="w-1/4 px-1 mb-2"
          v-for="(variant, index) in item.variants"
          :key="index"
          :to="{
            name: 'Product',
            params: { id: item.slug },
            query: { v: variant.id }
          }"
        >
          <b-image
            class=""
            :src="variant.image ? variant.image.thumb : $default_image_thumb"
            :alt="variant.slug"
            ratio="1by1"
            lazy
          ></b-image>
        </router-link>
      </div>
    </div>
    <div class="has-text-centered is-size-6 has-text-grey">
      <span>{{ product.code }}</span>
      <span class="new" v-if="product.new">new</span>
    </div>
    <div class="has-text-centered is-size-5 has-text-grey">
      {{ product.name }}
    </div>
    <div class="has-text-centered is-size-5 has-text-grey mb-4">
      <span v-if="product.price"> {{ product.price }} USD </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object
    }
  },
  data: () => ({
    url: {},
    product: {},
    index: 0,
    images: []
  }),
  computed: {
    item() {
      return this.data;
    }
  },
  watch: {
    data() {
      this.setData(this.data);
    }
  },
  methods: {
    setData(product, base) {
      // set url
      this.url = {
        name: "Product",
        params: {
          id: base ? base.slug : product.slug
        }
      };
      if (base) {
        this.url["query"] = { v: product.id };
      }
      // set product
      this.product = product;
      // set index to 0
      this.index = 0;
      //set images
      // this.images = product.images.length ? product.images : [product.image];
    },
    incrementIndex() {
      if (this.index == this.images.length - 1) this.index = 0;
      else this.index++;
    },
    decrementIndex() {
      if (this.index == 0) this.index = this.images.length - 1;
      else this.index--;
    }
  },
  mounted() {
    this.setData(this.data);
  }
};
</script>

<style lang="scss" scoped>
$baseMargin: 0.75rem;
$baseColor: #ad9861;
.gallery {
  position: relative;
  .gallery-control {
    position: absolute;
    bottom: $baseMargin;
    cursor: pointer;
    color: $baseColor;
    // border: 2px solid $baseColor;
  }
  .gallery-control-left {
    right: 2rem + $baseMargin;
  }
  .gallery-control-right {
    right: $baseMargin;
  }
}
.new {
  // position: absolute;
  // top: $baseMargin;
  // left: $baseMargin;
  margin-left: $baseMargin / 2;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  // height: 1.5rem;
  padding: 0 3px;
  border: 2px solid $baseColor;
  color: $baseColor;
  text-transform: uppercase;
  border: 2px solid $baseColor;
  // margin-left: 5px;
}
</style>
