<template>
  <div class="container p-4 flex items-center">
    <b-taglist>
      <b-tag size="is-large" rounded type="is-white">
        <router-link class="has-text-grey" :to="{ name: 'Home' }">
          <b-icon icon="home" type="is-grey"></b-icon>
        </router-link>
      </b-tag>
      <b-tag
        v-if="$route.name !== 'ProductList'"
        size="is-large"
        :type="!$route.query.show ? 'is-light' : 'is-white'"
        rounded
      >
        <router-link
          class="has-text-grey"
          :to="{ name: $route.name, params: { id: $route.params.id } }"
          ><span v-if="data.slug != 'new'">All</span>
          {{ data.name }}</router-link
        >
      </b-tag>
      <b-tag
        v-if="data.slug != 'new' && $route.name !== 'ProductList'"
        size="is-large"
        :type="$route.query.show == 'new' ? 'is-light' : 'is-white'"
        rounded
      >
        <router-link
          class="has-text-grey"
          :to="{
            name: $route.name,
            params: { id: $route.params.id },
            query: { show: 'new' }
          }"
          >New</router-link
        >
      </b-tag>
      <b-tag
        v-for="child in data.children"
        :key="child.id"
        size="is-large"
        :type="$route.query.show == child.slug ? 'is-light' : 'is-white'"
        rounded
      >
        <router-link
          class="has-text-grey"
          :to="{
            name: $route.name,
            params: { id: $route.params.id },
            query: { show: child.slug }
          }"
          >{{ child.name }}</router-link
        >
      </b-tag>
      <b-tag
        v-if="$route.name == 'ProductList' && $route.query.search"
        size="is-large"
        type="is-light"
        rounded
        closable
        @close="$router.push({ name: 'Home' })"
      >
        <router-link
          class="has-text-grey"
          :to="{
            name: 'Home'
          }"
          >Searching for:
          <strong>{{ $route.query.search }}</strong></router-link
        >
      </b-tag>
    </b-taglist>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  }
};
</script>
