var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container p-4 flex items-center"},[_c('b-taglist',[_c('b-tag',{attrs:{"size":"is-large","rounded":"","type":"is-white"}},[_c('router-link',{staticClass:"has-text-grey",attrs:{"to":{ name: 'Home' }}},[_c('b-icon',{attrs:{"icon":"home","type":"is-grey"}})],1)],1),(_vm.$route.name !== 'ProductList')?_c('b-tag',{attrs:{"size":"is-large","type":!_vm.$route.query.show ? 'is-light' : 'is-white',"rounded":""}},[_c('router-link',{staticClass:"has-text-grey",attrs:{"to":{ name: _vm.$route.name, params: { id: _vm.$route.params.id } }}},[(_vm.data.slug != 'new')?_c('span',[_vm._v("All")]):_vm._e(),_vm._v(" "+_vm._s(_vm.data.name))])],1):_vm._e(),(_vm.data.slug != 'new' && _vm.$route.name !== 'ProductList')?_c('b-tag',{attrs:{"size":"is-large","type":_vm.$route.query.show == 'new' ? 'is-light' : 'is-white',"rounded":""}},[_c('router-link',{staticClass:"has-text-grey",attrs:{"to":{
          name: _vm.$route.name,
          params: { id: _vm.$route.params.id },
          query: { show: 'new' }
        }}},[_vm._v("New")])],1):_vm._e(),_vm._l((_vm.data.children),function(child){return _c('b-tag',{key:child.id,attrs:{"size":"is-large","type":_vm.$route.query.show == child.slug ? 'is-light' : 'is-white',"rounded":""}},[_c('router-link',{staticClass:"has-text-grey",attrs:{"to":{
          name: _vm.$route.name,
          params: { id: _vm.$route.params.id },
          query: { show: child.slug }
        }}},[_vm._v(_vm._s(child.name))])],1)}),(_vm.$route.name == 'ProductList' && _vm.$route.query.search)?_c('b-tag',{attrs:{"size":"is-large","type":"is-light","rounded":"","closable":""},on:{"close":function($event){return _vm.$router.push({ name: 'Home' })}}},[_c('router-link',{staticClass:"has-text-grey",attrs:{"to":{
          name: 'Home'
        }}},[_vm._v("Searching for: "),_c('strong',[_vm._v(_vm._s(_vm.$route.query.search))])])],1):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }