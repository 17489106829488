<template>
  <div class="shadow-xs" id="header" ref="header">
    <div class="container px-4">
      <div class="px-2">
        <div class="-mx-4 h-24 flex flex-wrap items-center">
          <div class="w-1/4 px-2">
            <div class="flex justify-start">
              <router-link :to="{ name: 'Home' }">
                <img
                  alt="mkthemedattractions-logo"
                  src="@/assets/logo.svg"
                  class="h-16 m-0"
                />
              </router-link>
            </div>
          </div>
          <div class="w-1/2 px-2">
            <Search />
          </div>
          <div class="w-1/4 px-2 flex justify-end">
            <MenuToggle />
            <CartIndicator
              v-if="
                (userHasPermission('write-custom-carts') &&
                  $store.getters.userRole != 'super-admin') ||
                  (userHasPermission('write-carts') &&
                    $store.getters.userRole != 'super-admin')
              "
            />
            <b-dropdown
              aria-role="list"
              :close-on-click="false"
              position="is-bottom-left"
            >
              <b-button
                slot="trigger"
                slot-scope="{ active }"
                class=""
                type="is-grey"
                inverted
              >
                <b-icon icon="account-circle-outline"></b-icon>
                <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
              </b-button>
              <b-dropdown-item aria-role="listitem">
                <small>Logged in as</small>
                <br />
                {{
                  $store.getters.activeUser.is_client
                    ? `${
                        $store.getters.clientCart
                          ? $store.getters.clientCart.client_name
                          : ""
                      } via ${$store.getters.activeUser.name}`
                    : $store.getters.activeUser
                    ? $store.getters.activeUser.name
                    : ""
                }}
              </b-dropdown-item>
              <b-dropdown-item
                aria-role="listitem"
                has-link
                v-if="
                  userHasPermission('write-account') &&
                    $store.getters.userRole != 'super-admin'
                "
              >
                <router-link
                  :to="{ name: 'My Account' }"
                  class="has-text-black"
                >
                  Account
                </router-link>
              </b-dropdown-item>
              <b-dropdown-item
                aria-role="listitem"
                has-link
                v-if="
                  userHasPermission('write-carts') &&
                    $store.getters.userRole != 'super-admin'
                "
              >
                <router-link
                  :to="{ name: 'My Orders' }"
                  class="flex has-text-black"
                >
                  My Orders
                </router-link>
              </b-dropdown-item>
              <b-dropdown-item
                aria-role="list-item"
                v-if="userHasPermission('read-dashboard')"
              >
                <router-link :to="{ name: 'Dashboard' }" class="has-text-black"
                  >Dashboard</router-link
                >
              </b-dropdown-item>
              <b-dropdown-item aria-role="listitem" has-link>
                <a @click="logout" class="has-text-black"> Logout </a>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Search from "@/components/client/Search";
import CartIndicator from "@/components/client/CartIndicator";
import MenuToggle from "@/components/client/MenuToggle";
export default {
  components: {
    Search,
    CartIndicator,
    MenuToggle
  },
  data: () => ({
    loginName: null
  }),
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
    userHasPermission(data) {
      return this.$store.getters.userHasPermission(data);
    }
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
#header {
  background: white;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 8;
}
</style>
